<template>
  <el-dialog append-to-body custom-class="createSegmentMarketingPopup" :visible.sync="visible" width="1000px">
    <!-- Header -->
    <div slot="title" class="dialog-header">
      <template v-if="!isEdit">Create - {{ toPascalCase(channel) }} Audience Sync</template>
      <template v-else>Update - {{ toPascalCase(channel) }} Audience Sync (#{{ id }})</template>
    </div>

    <div>
      <el-form ref="frm" label-position="top" :model="form" :rules="formRules">
        <el-row class="w-full" :gutter="30">
          <!-- Name -->
          <el-col :span="12" class="pb-6">
            <el-form-item label="Sync Name (For Your Reference)" prop="name">
              <el-input v-model="form.name" max="300"></el-input>
            </el-form-item>
          </el-col>

          <!-- Segment To Sync-->
          <el-col :span="12" class="pb-6">
            <el-form-item label="Segment To Sync" prop="segment_id">
              <el-select v-model="form.segment_id" filterable placeholder="Select Segment To Sync">
                <el-option v-for="item in segmentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- Frequency Type -->
          <el-col :span="12" class="pb-6">
            <el-form-item label="Export Frequency" prop="sync_type">
              <el-radio-group border v-model="form.sync_type" size="small">
                <el-radio label="onetime" border>One-time</el-radio>
                <el-radio label="recurring" border>Recurring</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <!-- Frequency Of Sync -->
          <el-col :span="12" class="pb-6">
            <template v-if="form.sync_type == 'recurring'">
              <!-- Frequency Unit -->
              <el-form-item class="frequencyForm" label="Export Frequency" @change="onFrequencyUnitChange">
                <el-select v-model="form.recurring_unit" size="small" @change="onFrequencyUnitChange">
                  <el-option label="Daily" value="day"> </el-option>
                  <el-option label="Weekly" value="week"> </el-option>
                  <el-option label="Monthly" value="month"> </el-option>
                </el-select>
                <!-- Week Day -->
                <el-select v-model="form.recurring_value" size="small" style="width: 160px" v-if="form.recurring_unit == 'week'">
                  <el-option :value="0" label="Every Sunday"></el-option>
                  <el-option :value="1" label="Every Monday"></el-option>
                  <el-option :value="2" label="Every Tuesday"></el-option>
                  <el-option :value="3" label="Every Wednesday"></el-option>
                  <el-option :value="4" label="Every Thursday"></el-option>
                  <el-option :value="5" label="Every Friday"></el-option>
                  <el-option :value="6" label="Every Saturday"></el-option>
                </el-select>
                <!-- Month Day -->
                <el-select v-model="form.recurring_value" size="small" style="width: 130px" v-if="form.recurring_unit == 'month'">
                  <el-option v-for="index in 30" :key="index" :label="'On Date: ' + index" :value="index"></el-option>
                </el-select>
                &nbsp;AT&nbsp;
                <el-select style="width: 120px" v-model="form.recurring_hour" size="small" filterable>
                  <el-option v-for="item in get24HoursList()" :key="item.key" :label="item.label" :value="item.key"> </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-col>
        </el-row>

        <!-- Facebook Provider Data -->
        <template v-if="channel == 'facebook'">
          <el-row class="w-full" :gutter="30">
            <!-- Select FB Account -->
            <el-col :span="12">
              <el-form-item label="Select Facebook Ad Account" prop="target_provider_id">
                <el-select v-model="form.target_provider_id" filterable @change="fetchFBAudienceList">
                  <el-option v-for="(provider, index) in providerList" :value="provider.id" :label="provider.provider_name" :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- Select FB Audience -->
            <el-col :span="12" class="overflow-hidden">
              <!-- Add Audience Button -->
              <div v-if="form.target_provider_id" class="position-absolute addAudienceButton right-3" style="z-index: 1000">
                <el-button size="mini" type="primary" plain @click="onShowCreateAudienceDialog">Add Audience</el-button>
              </div>
              <!-- Select Attribute To Update -->
              <el-form-item label="Select Facebook Audience" prop="target_audiance_id">
                <el-select v-if="!fetchingAudienceList" v-model="form.target_audiance_id" filterable class="">
                  <el-option v-for="(audience, index) in audienceList" :value="audience.id" :label="audience.name" :key="index">
                    <span style="float: left">{{ audience.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ audience.id }}</span>
                  </el-option>
                </el-select>
                <div class="pl-4" v-else>
                  <beat-loader :loading="true" color="rgb(220, 223, 230)" size="8px"></beat-loader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-form>
    </div>

    <!-- Footer -->
    <div slot="footer" class="footer">
      <el-button type="primary" @click="onCreate" :loading="saving" v-if="!isEdit">Start Sync</el-button>
      <el-button type="primary" @click="onCreate" :loading="saving" v-else>Update Sync</el-button>
      <el-button type="danger" plain @click="visible = false">Cancel</el-button>
    </div>

    <CreateFbAudiencePopup ref="createFbAudiencePopup" @success="onAudienceCreateSuccess"></CreateFbAudiencePopup>
  </el-dialog>
</template>

<style lang="scss" src="./segmentRemarketingPopup.scss"></style>

<script>
import segmentRemarketingPopupComponent from './segmentRemarketingPopupComponent';
export default segmentRemarketingPopupComponent;
</script>
