import { fetchChannelProviderList } from '@/services/campaign';
import { getSegmentList } from '@/services/sessionFilter';
import segmentRemarketingModule from '@/services/segmentRemarketing';
import CreateFbAudiencePopup from '@/components/createFacebookAudiencePopup/createFacebookAudiencePopup';
import { getFacebookAudienceList } from '@/services/channelIntegration';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
import * as _ from 'lodash';

export default {
  name: 'segmentRemarketingPopup',
  props: {
    channel: {
      type: String,
      requried: true
    }
  },
  components: {
    BeatLoader,
    CreateFbAudiencePopup
  },
  data() {
    return {
      visible: false,
      showLoader: false,
      isEdit: false,
      saving: false,

      fetchingAudienceList: null,
      providerList: null,
      audienceList: null,

      id: null,
      segmentList: null,
      form: {
        name: '',
        segment_id: null,
        sync_type: 'recurring',
        recurring_unit: 'day',
        recurring_value: 1,
        recurring_hour: 16.5,

        target_provider_id: null,
        target_audiance_id: null
      },

      // Form validation
      formRules: {
        name: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur'
          }
        ],
        segment_id: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur'
          }
        ],
        target_provider_id: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur'
          }
        ],
        target_audiance_id: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur'
          }
        ]
      }
    };
  },

  methods: {
    showDialog(syncToUpdate) {
      this.id = null;
      this.saving = false;

      this.isEdit = false;
      if (syncToUpdate) {
        this.isEdit = true;

        // Set params in form.
        syncToUpdate.recurring_hour = parseFloat(syncToUpdate.recurring_hour);
        this.id = syncToUpdate.id;
        this.form.name = syncToUpdate.name;
        this.form.segment_id = syncToUpdate.segment_id;
        this.form.sync_type = syncToUpdate.sync_type;
        this.form.recurring_unit = syncToUpdate.recurring_unit;
        this.form.recurring_value = syncToUpdate.recurring_value;
        this.form.recurring_hour = syncToUpdate.recurring_hour;

        if (this.channel == 'facebook') {
          let metadata = JSON.parse(syncToUpdate.tp_sync_metadata);
          this.form.target_provider_id = metadata.target_provider_id;
          this.form.target_audiance_id = metadata.target_audiance_id;

          this.fetchFBAudienceList();
        }
      } else {
        this.form = {
          name: '',
          segment_id: null,
          sync_type: 'recurring',
          recurring_unit: 'day',
          recurring_value: 1,
          recurring_hour: 16.5,

          target_provider_id: null,
          target_audiance_id: null
        };
      }

      this.fetchFBAccountList();

      this.visible = true;
    },

    onFrequencyUnitChange() {
      if (this.form.recurring_unit == 'week') {
        this.form.recurring_value = 1;
      } else if (this.form.recurring_unit == 'month') {
        this.form.recurring_value = 1;
      }
    },

    async fetchSegmentList() {
      if (this.segmentList != null) return;
      try {
        let result = await getSegmentList();
        this.segmentList = result.data;
        console.log('segment list result is', this.segmentList);
      } catch (err) {
        this.reportError(err);
        this.errorToast(`Failed to fetch segments. Something went wrong.`);
      }
    },

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs['frm'].validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    async onCreate() {
      try {
        // Validate Form.
        let isValid = await this.validateForm();
        if (!isValid) return;

        this.saving = true;

        let params = {
          name: this.form.name,
          channel: this.channel,
          segment_id: this.form.segment_id,
          sync_type: this.form.sync_type,
          recurring_unit: this.form.recurring_unit,
          recurring_value: this.form.recurring_value,
          recurring_hour: this.form.recurring_hour,

          tp_sync_metadata: JSON.stringify({
            target_provider_id: this.form.target_provider_id,
            target_audiance_id: this.form.target_audiance_id
          })
        };

        if (this.id) {
          // Update
          await segmentRemarketingModule.update(this.id, params);
          this.successToast('Sync updated.');
        } else {
          // Create
          this.form.channel = this.channel;
          await segmentRemarketingModule.create(params);
          this.successToast('Sync created.');
        }

        this.visible = false;
        this.$emit('success');
        this.saving = false;
      } catch (err) {
        this.saving = false;
        this.reportError(err);
        this.errorToast(`Something's wrong. Please contact support.`);
      }
    },

    //#region ------------ Manage Audience --------------------

    async onShowCreateAudienceDialog() {
      let providerMetadata = _.find(this.providerList, (provider) => {
        return provider.id == this.form.target_provider_id;
      });
      this.$refs.createFbAudiencePopup.showDialog(this.form.target_provider_id, providerMetadata);
    },

    async onAudienceCreateSuccess(data) {
      this.form.target_audiance_id = data.id;
      await this.fetchFBAudienceList();
    },

    async fetchFBAccountList() {
      try {
        this.loading = true;
        let params = {
          channel: 'social',
          provider_type: 'facebook',
          readArchived: this.readOnly
        };
        let result = await fetchChannelProviderList(params, this);
        if (result.data.success) {
          this.providerList = result.data.data;
          if (result.data.data.length <= 0) {
            this.warningToast('No Facebook account is configured yet.');
          }
          console.log('Fetch list', this.providerList);
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong');
      } finally {
        this.loading = false;
      }
    },

    async fetchFBAudienceList() {
      try {
        this.fetchingAudienceList = true;
        let result = await getFacebookAudienceList(this.form.target_provider_id);
        this.audienceList = result.data;
        this.fetchingAudienceList = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch Facebook Audience List!');
      }
    }

    //#endregion ------------ Manage Audience --------------------
  },

  mounted() {
    this.fetchSegmentList();
  }
};
