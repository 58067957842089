import CreateSegmentRemarketing from '@/components/segmentRemarketingPopup/segmentRemarketingPopup';
import segmentRemarketingModule from '@/services/segmentRemarketing';

const moment = require('moment');
const _ = require('lodash');

export default {
  name: 'segmentRemarketing',
  components: {
    CreateSegmentRemarketing
  },
  props: {
    channel: {
      required: true
    }
  },
  watch: {
    channel: {
      handler() {
        this.mounted();
      }
    }
  },

  data() {
    return {
      // creatingJourney: false
      fetchingData: true,
      finalList: [],

      searchFilter: '',
      statusFilter: [],
      filterOptions: {
        status: [
          { label: 'Draft', value: 'draft' },
          { label: 'Running', value: 'running' },
          { label: 'Paused', value: 'paused' }
        ]
      },

      pageOptions: {
        pageSize: 10,
        pageNumber: 1,
        total: null
      }
    };
  },

  methods: {
    onCreateNewClick() {
      this.$refs.creator.showDialog();
    },

    showSyncDetails(sync) {
      this.$refs.creator.showDialog(sync);
    },

    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.pageNumber = 1;
      this.fetchList();
    },

    fetchList(isPageLoad) {
      this.fetchingData = true;

      let params = {
        search: this.searchFilter,
        statusFilter: this.statusFilter.length > 0 ? this.statusFilter : undefined,
        pageOptions: this.pageOptions
      };

      // Modify Query-String
      if (!isPageLoad) {
        const query = Object.assign({}, this.$route.query);
        query.search = params.search;
        query.statusFilter = JSON.stringify(params.statusFilter);
        query.pageOptions = JSON.stringify(params.pageOptions);
        this.$router.push({ query });
      }

      segmentRemarketingModule
        .fetchList(this.channel, params)
        .then((result) => {
          this.finalList = result.data.list;
          this.pageOptions.total = result.data.total;

          console.log('result is', result.data);

          for (var i = 0; i < this.finalList.length; i++) {
            this.finalList[i].updated_at = this.finalList[i].updated_at && this.finalList[i].updated_at != 'N/A' ? this.getDisplayTime(this.finalList[i].updated_at) : 'N/A';

            // Updator
            this.updatorName = null;
            if (this.finalList[i].updator_first_name) {
              this.updatorName = this.finalList[i].updator_first_name + ' ' + this.finalList[i].updator_last_name;
            }
            this.finalList[i].updator_name = this.updatorName;
          }

          this.fetchingData = false;
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast('Something went wrong.');
          this.fetchingData = false;
        });
    },

    getDisplayTime(date) {
      if (!date) {
        return `N/A`;
      }
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm:ss A');
    },

    showDeleteConfirm(sync) {
      this.$swal({
        title: 'Delete Sync?',
        text: "You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Delete Sync'
      }).then(async () => {
        await segmentRemarketingModule.delete(sync.id, this);
        this.successToast('Sync deleted.');
        this.fetchList();
      });
    },

    async handleCommand(command) {
      try {
        if (command.name == 'pause') {
          let sync = command.data;
          sync.status = 'paused';
          segmentRemarketingModule.update(sync.id, 'paused', this);
          this.successToast('Sync Paused');
        } else if (command.name == 'running') {
          let sync = command.data;
          sync.status = 'running';
          segmentRemarketingModule.update(sync.id, 'running', this);
          this.successToast('Sync started again.');
        } else if (command.name == 'delete') {
          this.showDeleteConfirm(command.data);
        }
      } catch (err) {
        this.errorToast(`Something's wrong. Please contact support.`);
        console.error(err);
      }
    }
  },

  mounted() {
    // Modify Query-String
    if (['facebook', 'google'].indexOf(this.channel) < 0) {
      // replace with 404
      this.$router.replace('/404');
      return;
    }

    this.fetchList(true);
  }
};
