<template>
  <div id="segmentRemarketingList">
    <!-- Header -->
    <div class="header">
      {{ channel ? toPascalCase(channel) : channel }} Audience Sync
      <!-- <span v-if="!fetchingData">({{ pageOptions.total }})</span> -->
      <div class="filters">
        <el-input class="searchCampaignInput" prefix-icon="el-icon-search" size="small" v-model="searchFilter" @input="onSearch" placeholder="Search Sync"> </el-input>
        <el-select @change="resetPaginationAndSearch" class="statusFilter" size="small" v-model="statusFilter" multiple collapse-tags placeholder="Sync Status">
          <el-option v-for="item in filterOptions.status" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>

        <div style="float: right">
          <el-button size="small" type="primary" @click="onCreateNewClick"> Create Audience Sync </el-button>
        </div>
      </div>
    </div>

    <!-- Loader -->
    <div class="loadingDiv" v-if="fetchingData && finalList" v-loading="true" style="min-height: 300px"></div>

    <!--  List -->
    <div class="journeyList" v-if="!fetchingData && finalList && finalList.length > 0">
      <!-- Header Row -->
      <div class="journeyItem headerRow">
        <!-- General Details -->
        <div class="details">Ad Network</div>

        <!-- General Details -->
        <div class="segmentName">Segment To Sync</div>

        <!-- General Details -->
        <!-- <div class="segmentName">Audience Name</div> -->

        <!-- Updated At -->
        <div class="startTime">Updated At</div>

        <div class="optionsBox"></div>
      </div>

      <!-- Journey List -->
      <div class="journeyItem" v-for="(sync, index) in finalList" :key="index">
        <!-- General Details -->
        <div class="details flex">
          <div class="flex-none -ml-2 mr-2 pt-0.5">
            <template v-if="channel == 'facebook'">
              <img src="@/assets/images/remarketing/facebook-ad.png" style="width: 40px" />
            </template>
            <template v-else>
              <img src="@/assets/images/remarketing/google-ad.png" style="width: 40px" />
            </template>
          </div>
          <div>
            <div>
              <div class="detailsLink" @click="showSyncDetails(sync)">
                {{ sync.name }}
              </div>
            </div>
            <div class="metaInfo">
              <div class="statusTag running" v-if="sync.status == 'running'">ACTIVE</div>
              <div class="statusTag suspended" v-if="sync.status == 'paused'">PAUSED</div>
            </div>
          </div>
        </div>

        <div class="segmentName">
          {{ sync.segment.name }}
        </div>

        <!-- <div class="segmentName">
          {{ sync.tp_audiance_name }}
        </div> -->

        <div class="startTime">
          <div>{{ sync.updated_at }}</div>
          <div v-if="sync.updator_name">By {{ sync.updator_name }}</div>
        </div>
        <div class="optionsBox">
          <el-dropdown @command="handleCommand">
            <i class="el-icon-more"></i>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item v-if="sync.status == 'running'" :command="{ data: sync, name: 'pause' }">Pause Syncing</el-dropdown-item> -->
              <el-dropdown-item v-if="sync.status == 'paused'" :command="{ data: sync, name: 'running' }">Re-start Sync</el-dropdown-item>
              <el-dropdown-item :command="{ data: sync, name: 'delete' }">Delete</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <!-- Bottom Pagination -->
      <div class="pagination pagination-bottom">
        <div class="totalCount">Total {{ pageOptions.total }} Syncs</div>
        <el-pagination @current-change="fetchList" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
      </div>
    </div>

    <div class="rounded-md border bg-white mt-3 text-center text-gray-400 py-60" v-if="!fetchingData && finalList && finalList.length == 0">No Data Found.</div>

    <CreateSegmentRemarketing ref="creator" :channel="channel" @success="fetchList"></CreateSegmentRemarketing>
  </div>
</template>
<style lang="scss" src="./segmentRemarketing.scss"></style>

<script>
import segmentRemarketingComponent from './segmentRemarketingComponent';
export default segmentRemarketingComponent;
</script>
