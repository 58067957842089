const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.fetchList = function (channel, params) {
  return axios.post(baseUrlNew + `segment-remarketing/list/${channel}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(baseUrlNew + `segment-remarketing/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.create = function (params) {
  return axios.post(baseUrlNew + `segment-remarketing/create`, params, {
    headers: buildAuthHeaders()
  });
};

exports.update = function (id, params) {
  return axios.put(baseUrlNew + `segment-remarketing/${id}/`, params, {
    headers: buildAuthHeaders()
  });
};

exports.updateStatus = function (id, status) {
  return axios.put(
    baseUrlNew + `segment-remarketing/${id}/status/${status}`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.delete = function (id) {
  return axios.delete(baseUrlNew + `segment-remarketing/${id}`, {
    headers: buildAuthHeaders()
  });
};
